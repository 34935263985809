/**
 * Required library
 * - jquery validator
 */
var CartInformation = {
    validator: null,

    rules: {
        cart_name: {
            required: true,
            maxlength: 255
        },
    },

    ruleMessages: {
        cart_name: {
            required: "<em>Please enter cart name</em>",
            regex: "Enter a valid cart name"
        }
    },

    init: function(submitFormCallback) {
        var cartEl = $('#modal-cart-info');

        $('#save-cart-info').click(CartInformation.onSave);
        cartEl.on('hide', CartInformation.onModalHide);

        CartInformation.initValidation(submitFormCallback);
    },

    initValidation: function(submitFormCallback) {
        CartInformation.validator = $("#cart-info-form").validate({
            rules: CartInformation.rules,
            messages: CartInformation.ruleMessages,
            submitHandler: submitFormCallback
        });
    },

    // required to use
    onModalShow: function(callback) {
        var createCartModal = UIkit.modal("#modal-cart-info", {
            escClose: false,
            bgClose: false
        });

        createCartModal.show().then(callback);
    },

    onSave: function() {
        $("#cart-info-form").submit();
    },

    onModalHide: function() {
        CartInformation.validator.resetForm(); // clean error messages
        $("#cart-info-form")[0].reset(); // clean input data
    }
};


// Customizer Page
CartInformation.events = {
    carts: [],
    activeCart: {},

    initEventsOnCartList: function() {
        var self = CartInformation.events;
        var $cartListElement = $("#modal-cart-lists");

        $cartListElement.find('#start-new-cart').click(self.onShowCreateNewCartModal);
        $cartListElement.find('#activate-cart').click(self.savedOnSelectedCart);
        $cartListElement.find('#show-save-design-form').click(self.onShowSavedDesignForm);
        $cartListElement.find('.uk-checkbox').click(self.onSelectCart);

        // 'unbind' prevent multiple trigger
        $('#modal-save-design-form #save-to-savedDesign').unbind().click(self.savedOnSavedDesignPage);
        $("#modal-cart-info #save-cart-info").unbind().click(self.saveOnCreatedCart);
    },

    onShowCartsList: function() {
        var element = $("#modal-cart-lists");
        var template = _.template($("#tmpl-save-cart-add-to-another").html());
        var data = _.sortBy(CartInformation.events.carts, "is_active");

        element.find(".template-container").html(
            template({
                carts: data.reverse()
            })
        );

        UIkit.modal(element, {
            bgClose: false,
            escClose: false,
            stack: true
        }).show();

        CartInformation.events.initEventsOnCartList();
    },

    onShowCartsListReversibleSavedDesign: function() {
        var element = $("#modal-cart-lists");
        var template = _.template($("#tmpl-save-cart-add-to-another").html());
        var data = _.sortBy(CartInformation.events.carts, "is_active");

        element.find(".template-container").html(
            template({
                carts: data.reverse()
            })
        );

        UIkit.modal(element, {
            bgClose: false,
            escClose: false,
            stack: true
        }).show();

        CartInformation.events.initEventsOnCartList();

        // Change to Move to Cart btn
        $('#activate-cart').text('Move to Cart');
        // Hide Save Design btn
        $('#show-save-design-form').hide();
    },

    onShowCreateNewCartModal: function() {
        UIkit.modal("#modal-cart-info", {
            bgClose: false,
            escClose: false,
            stack: true
        }).show();
    },

    saveOnCreatedCart: function() {
        var cartApi = new CartApi(location.origin);
        var element = $("#modal-cart-info");
        var $form = element.find("#cart-info-form").parsley();
        var parentWindow = window.parent;
        var onReversibleSide2 = _.isEqual(ub.config.isEmbedded, "1");
        var errorHandler = function() {
            parentWindow.Loader.endloading(() => {
                UIkit.modal(element).hide();
                UIkit.notification({
                    message: "Something went wrong. Please try again.",
                    status: 'danger',
                    pos: 'top-right'
                });
            })
        };
        var data = {
            cart_name: element.find("[name='cart_name']").val(),
            cart_note: element.find("[name='cart_note']").val()
        }

        if(!$form.isValid()) {
            element.find("[name='cart_name']").css("border", "red solid 1px");
        } else {

            parentWindow.Loader.customizer.saving();
            parentWindow.Loader.loading(1, 9);

            _.delay(() => {
                cartApi.create(data, res => {
                    if(!res.success) {
                        errorHandler();
                    }
                    sessionStorage.setItem("activeCartName", data.cart_name);
                    (onReversibleSide2)
                        ? ProlookReversible.funcs.onSaveDesignSide1()
                        : ProlookSaveDesign.events.onClickSave();
                }, errorHandler);
            }, 500);
        }

    },

    onSelectCart: function(event) {
        var $element = $(event.target);
        var $modalElement = $("#modal-cart-lists");

        $modalElement.find(".uk-checkbox").prop("checked", false);
        $element.prop("checked", true);
    },

    savedOnSelectedCart: function() {
        var cartApi = new CartApi(location.origin);
        var $modalElement = $("#modal-cart-lists");
        var checkedCartID = $modalElement.find('.uk-checkbox:checked').val();
        var onReversibleSide2 = _.isEqual(ub.config.isEmbedded, "1");
        var parentWindow = window.parent;
        var errorHandler = function($message) {
            parentWindow.Loader.endloading(() => {
                UIkit.modal($modalElement).hide();
                UIkit.notification({
                    message: $message ?? "Something went wrong. Please try again.",
                    status: 'danger',
                    pos: 'top-right'
                });
            });
        }

        parentWindow.Loader.customizer.saving();
        parentWindow.Loader.loading(1, 9);

        if(checkedCartID) {
            var cartObject = _.find(CartInformation.events.carts, {
                id: parseInt(checkedCartID)
            });

            _.delay(() => {
                cartApi.activate(checkedCartID, res => {
                    if(!res.success) {
                        errorHandler(res.message + ". Please refresh the page and log in again.");
                        return;
                    }
                    sessionStorage.setItem("activeCartName", cartObject.name);
                    (onReversibleSide2)
                        ? ProlookReversible.funcs.onSaveDesignSide1()
                        : ProlookSaveDesign.events.onClickSave();

                }, errorHandler);
            }, 500);
        }
    },

    savedOnSavedDesignPage: () => {
        var $saveDesignForm = $("#modal-save-design-form form");
        var title = $saveDesignForm.find(".save-design-title").val();
        var description = $saveDesignForm.find(".save-design-note").val();

        if (typeof PartInformation === 'object') {
            let Labels = ub.data.modifierLabels;

            if (QX7Rule.enabled) {
                Labels = QX7Rule.ModifierLabel.getLabels();
            }

            PartInformation.setupPartInformation(Labels, ub.current_material.settings);
            console.log(ub.current_material.settings.part_info)
                  
        }
              
        if (title === "" || title.length === 0) {
            if($(".required-field").length > 0) { return; }

            var elError = '<div class="required-field">Please input a design title.</div>';
            $saveDesignForm.find(".save-design-title").parent().append(elError);
            return;
        }

        sessionStorage.setItem("designTitle", title);
        sessionStorage.setItem("designDescription", description);

        Loader.customizer.saving();
        Loader.loading(1, 1);

        // Reversible
        var isReversible = _.isEqual(ub.config.isReversible, "1");
        var onSide2 = _.isEqual(ub.config.isEmbedded, "1");

        // remove reversible key item
        sessionStorage.removeItem('side1_sd_id');
        sessionStorage.removeItem('side2_sd_id');

        // saved only
        // meaning item will only go to saved design page and not in hub
        if(isReversible && !onSide2) {
            ProlookReversible.funcs.onSaveDesignSide2(true);
        } else if (isReversible && onSide2) {
            ProlookReversible.funcs.onSaveDesignSide1(true);
        }

        _.delay(() => {        
            ProlookSaveDesign.funcs.savedDesign(response => {

                var redirect = function() {
                    _.delay(() => {
                        window.parent.onbeforeunload = null;
                        location.href = "/my-saved-designs";
                        Loader.completeloading();
                        sessionStorage.clear();
                    }, 7000);
                }

                var checkOtherSideId = function(side, sideId, otherSideId, callback) {
                    if (otherSideId === null) {
                        Loader.endloading(function() {
                            UIkit.notification({
                                message: 'Error: Unable to save the design because the reference ID for ' + side + ' is missing. Please try again.',
                                status: 'danger',
                                pos: 'top-right',
                                timeout: 10000
                            });
                        });

                        // delete last added saved design
                        // prevent duplication
                        ProlookSaveDesign.prolookApi.deleteDesign(sideId);
                        ProlookSaveDesign.prolookApi.getLastAdded(ub.user.id, (res) => {
                            if (res.success) {
                                var lastAddedId = res.saved_design.id;
                                ProlookSaveDesign.prolookApi.deleteDesign(lastAddedId);
                            }
                        });
                        return;
                    }

                    callback();
                }

                if(isReversible && !onSide2) {
                    // store active side design id
                    sessionStorage.setItem('side1_sd_id', response.sd_id);
                    // prepare data
                    var side = "Side 1"; otherSideId = sessionStorage.getItem('side2_sd_id'); savedDesignId = response.sd_id;
                    var prepareData = ProlookSaveDesign.funcs.reversibleData(savedDesignId, side, otherSideId);
                    // check otherSideId
                    checkOtherSideId(side, savedDesignId, otherSideId, () => {
                        // update Side 2
                        ProlookReversible.funcs.onUpdateSide2();
                        // after Side 2 updated, update Side 1
                        ProlookSaveDesign.prolookApi.updateDesign(prepareData, {});
                        // redirect to my-saved-designs page
                        redirect();
                    });
                } else if (isReversible && onSide2) {
                    // store active side design id
                    sessionStorage.setItem('side2_sd_id', response.sd_id);
                    // prepare data
                    var side = "Side 2"; otherSideId = sessionStorage.getItem('side1_sd_id'); savedDesignId = response.sd_id;
                    var prepareData = ProlookSaveDesign.funcs.reversibleData(savedDesignId, side, otherSideId);
                    // check otherSideId
                    checkOtherSideId(side, savedDesignId, otherSideId, () => {
                        // update Side 1
                        ProlookReversible.funcs.onUpdateSide1();
                        // after Side 2 updated, update Side 1
                        ProlookSaveDesign.prolookApi.updateDesign(prepareData, {});
                        // redirect to my-saved-designs page
                        redirect();
                    });
                } else {
                    // redirect to my-saved-designs page
                    redirect();
                }
            });
        }, 500);
    },

    onShowSavedDesignForm: () => {
        // remove the validation error for modal save design title
        $('#modal-save-design-form').find('.required-field').remove();

        UIkit.modal("#modal-save-design-form", {
            bgClose: false,
            escClose: false,
            stack: true
        }).show();

        // add event keyup on save design title
        $("#modal-save-design-form form .save-design-title").on("keyup", function() {
            if($(this).val() === "" || $(this).val().length === 0) {
                if($(".required-field").length > 0) { return; }

                var elError = '<div class="required-field">Please input a design title.</div>';
                $("#modal-save-design-form form").find(".save-design-title").parent().append(elError);
            } else {
                $("#modal-save-design-form form").find(".required-field").remove();
            }
        });
    },

    fetchCarts: () => {
        var cartApi = new CartApi(location.origin);

        cartApi.getAll(res => {
            if(!res.success) {
                console.warn("get carts response --> ", res);
            } else {
                CartInformation.events.carts = res.data;

                // get active cart
                CartInformation.events.activeCart = _.find(res.data, {
                    is_active: 1
                });
            }
        }, err => {
            console.error(err);
        });
    }
};

